import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Layout from '../components/layout'

import {
	Container,
	Section,
	Row,
	Column,
	ScrollableContainer,
} from '../components/layouts'

import {
	CardForm,
	OrganizationsForm,
	ProjectsForm,
	ThankYouMsj,
	Donate,
	Title
} from '../components/ui'

import {
	getOrgs,
	getProjects,
	selectOrg,
	selectProj,
	setDonateNow,
	clearOrgAndProj,
	clearProjects,
	clearSelectedProj,
	clearDonateNow,
	clearErrors,
} from '../factory'

import { getUrlParams } from '../util'

import theme from '../theme/theme'

const {
	donateNow
} = theme.sections

class DonatePage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			position: 0,
			matchingOrgs: [],
			org: null,
			proj: null,
			donation: null,
			cardNumber: null,
			cardCvc: null,
			postalCode: null,
			cardExpiry: null,
			validatedForm: false,
			loadDonate: false,
			searching: false
		}

		this.chooseOrg = this.chooseOrg.bind(this)
		this.searchOrgs = this.searchOrgs.bind(this)
		this.selectProject = this.selectProject.bind(this)
		this.setFormValue = this.setFormValue.bind(this)
		this.submitDonation = this.submitDonation.bind(this)
		this.nextPosition = this.nextPosition.bind(this)
		this.prevPosition = this.prevPosition.bind(this)
		this.resetPosition = this.resetPosition.bind(this)
	}

	componentDidMount() {
		this.props.onGetOrgs()
		this.props.onClearOrgAndProj()
		this.props.onClearProjects()
		this.props.clearErrors()
		this.setUrlParams()
	}

	componentWillUnmount() {
		this.props.clearDonateNow()
	}

	setUrlParams() {
		const queryURL = getUrlParams(window.location.search)
		if (queryURL.organization_title) {
			this.searchOrgs({target: {value: queryURL.organization_title}})
		}
	}

	chooseOrg(org) {
		this.setState({
			org: org
		})

		this.props.onSelectOrg(org)
		this.props.onGetProjects(org.id)

		this.nextPosition()
	}

	selectProject(proj) {
		this.props.onSelectProj(proj)

		this.setState({
			proj: proj
		}, () => {
			this.nextPosition()
		})
	}

	searchOrgs(e) {
		const orgs = this.props.organizations

		if (!orgs || !orgs.length) return

		const filteredOrgs = orgs.filter(org => (
			org.title.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1
		))

		this.setState({
			searching: e.target.value && e.target.value.length > 0,
			matchingOrgs: filteredOrgs,
			searchTerm: e.target.value
		})
	}

	setFormValue(event) {
		if (event.target) {
			this.setState({
				[event.target.name]: event.target.value,
			}, () => {
				const { generalValidation } = this.validateForm()

				this.setState({
					validatedForm: generalValidation
				})
			})
		} else {
			this.setState({
				[event.elementType]: event.complete,
			}, () => {
				const { generalValidation } = this.validateForm()

				this.setState({
					validatedForm: generalValidation
				})
			})
		}
	}

	submitDonation({data, stripeToken, onErrorCallback}) {
		this.props.onDonation({...this.state, ...data}, stripeToken, this.nextPosition, onErrorCallback)
		this.setState({
			loadDonate: true,
		})
	}

	nextPosition() {
		if (this.state.position === 2) {
			this.setState({
				loadDonate: false,
			})
		}

		if ((this.state.proj && this.state.position >= 0) || this.state.position === 0) {
			this.setState({
				position: this.state.position < 3 ? this.state.position + 1 : this.state.position,
			})
		}
	}

	prevPosition() {
		this.setState({
			position: this.state.position > 0 ? this.state.position - 1 : this.state.position,
		})
	}

	resetPosition() {
		this.setState({
			position: 0,
		})
	}

	render() {
		return (
			<Layout>
				<Section bg={donateNow.bg} paddingTop={'8em'} paddingBottom={'3em'}>
					<form onSubmit={this.submitDonation}>
						<ScrollableContainer position={this.state.position}>
							<Container maxWidth={'840px'}>
								<Row>
									<Column>
										<CardForm>
											<Row>
												<Title as="h1" fontSize={'33px'} marginTop={'0.5em'} style={{maxWidth: '90%' }}>Choose where you would like to donate</Title>
											</Row>
											<OrganizationsForm
												style={{marginTop: 0}}
												title={donateNow.forms.first.title}
												orgs={this.props.organizations}
												matchingOrgs={this.state.matchingOrgs}
												selectedOrg={this.props.selectedOrg}
												onSelectOrg={this.chooseOrg}
												onChangeSearch={this.searchOrgs}
												searching={this.state.searching}
												searchTerm={this.state.searchTerm}
												errors={this.props.error || this.state.error}
											/>
										</CardForm>
									</Column>
								</Row>
							</Container>
							<Container maxWidth={'840px'}>
								<Row>
									<Column>
										<CardForm>
											<ProjectsForm
												title={donateNow.forms.second.title}
												projects={this.props.projects}
												selectedProj={this.props.selectedProj}
												onSelectProj={this.selectProject}
												next={this.nextPosition}
												back={this.prevPosition}
												errors={this.props.error || this.state.error}
											/>
										</CardForm>
									</Column>
								</Row>
							</Container>
							<Container maxWidth={'840px'}>
								<Row>
									<Column>
										<CardForm>
											<Donate
												prevPosition={this.prevPosition}
												data={this.state}
												nextPosition={this.nextPosition}
												submit={this.submitDonation}
												errors={this.props.error || this.state.error}
											/>
										</CardForm>
									</Column>
								</Row>
							</Container>
							<Container maxWidth={'840px'}>
								<Row>
									<Column>
										<CardForm>
											<ThankYouMsj
												title={donateNow.forms.thanks.title}
												description1={donateNow.forms.thanks.description1}
												description2={donateNow.forms.thanks.description2}
												image={donateNow.forms.thanks.image}
												note={donateNow.forms.thanks.note}
												giveBtn={donateNow.forms.thanks.giveBtn}
												homeBtn={donateNow.forms.thanks.homeBtn}
												redirect={'/'}
												backToStart={this.resetPosition}
												selectedProj={this.props.selectedProj} />
										</CardForm>
									</Column>
								</Row>
							</Container>
						</ScrollableContainer>
					</form>
				</Section>
			</Layout>
		)
	}
}

DonatePage.propTypes = {
	stripe: PropTypes.object,
	organizations: PropTypes.array,
	selectedOrg: PropTypes.object,
	selectedProj: PropTypes.object,
	projects: PropTypes.object,
	onGetOrgs: PropTypes.func,
	onGetProjects: PropTypes.func,
	onSelectOrg: PropTypes.func,
	onSelectProj: PropTypes.func,
	onDonation: PropTypes.func,
	onClearOrgAndProj: PropTypes.func,
	onClearProjects: PropTypes.func,
	onClearSelectedProj: PropTypes.func,
	clearDonateNow: PropTypes.func,
	clearErrors: PropTypes.func,
	error: PropTypes.array,
	loading: PropTypes.bool
}

const mapStateToProps = (state) => {
	return {
		organizations: state.donateNow.organizations,
		selectedOrg: state.donateNow.selectedOrg,
		projects: state.donateNow.projects,
		selectedProj: state.donateNow.selectedProj,
		error: state.donateNow.error ? Object.values(state.donateNow.error) : null,
		loading: state.donateNow.loading
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onGetOrgs: () => dispatch(getOrgs()),
		onSelectOrg: (org) => dispatch(selectOrg(org)),
		onGetProjects: (orgId) => dispatch(getProjects(orgId)),
		onSelectProj: (proj) => dispatch(selectProj(proj)),
		onClearOrgAndProj: () => dispatch(clearOrgAndProj()),
		onClearProjects: () => dispatch(clearProjects()),
		onClearSelectedProj: () => dispatch(clearSelectedProj()),
		onDonation: (params, stripe, callBack, onErrorCallback) => dispatch(setDonateNow(params, stripe, callBack, onErrorCallback)),
		clearDonateNow: () => dispatch(clearDonateNow()),
		clearErrors: () => dispatch(clearErrors()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DonatePage)
